import { useCallback, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Desktop16.css";

const Desktop16 = () => {
  const navigate = useNavigate();
   const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login"); // Ensure correct path for login route
  }, [navigate]);

  const [college3, setCollege3] = useState('');
  const [degree, setDegree] = useState('');
  const [error, setError] = useState('');

  const handleChangeCollege = (event) => {
    setCollege3(event.target.value);
  };

  const handleChangeDegree = (event) => {
    setDegree(event.target.value);
  };

  const onFrameButtonClick = useCallback(() => {
    if (!college3 || !degree) {
      setError("Please fill in all fields.");
      return;
    }

    localStorage.setItem('college3', college3);
    localStorage.setItem('degree', degree);
    navigate("/Education2");
  }, [navigate, college3, degree]);

  const onBackTextClick = useCallback(() => {
    navigate("/Add-Name");
  }, [navigate]);

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="desktop-93">
      <div className="hero15">
        <img
          className="ad-placeholder-icon6"
          alt=""
          src="Design3.png"
        />
        <div className="content26">
          <div className="content-child17" />
          <div className="tell-us-about12">Tell us about yourself</div>
          <div className="add-your-educational4">
            Add your educational background. Add upto 3 degrees.
          </div>
          <TextField
            className="content-child18"
            color="primary"
            label="Enter College Name"
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "57px" }, width: "405px" }}
            value={college3}
            onChange={handleChangeCollege}
          />

          <FormControl
            variant="outlined"
            sx={{ m: 1, width: 405 }}
            className="content-child19"
          >
            <InputLabel id="select-degree-label">Enter Degree Name</InputLabel>
            <Select
              labelId="select-degree-label"
              id="select-degree"
              onChange={handleChangeDegree}
              label="Enter Degree Name"
              sx={{ height: 57 }}
              value={degree}
            >
<MenuItem value=""><em>None</em></MenuItem>
<MenuItem value="BDS">Bachelor of Dental Surgery</MenuItem>
<MenuItem value="BAMS">Bachelor of Ayurvedic Medicine and Surgery</MenuItem>
<MenuItem value="BUMS">Bachelor of Unani Medicine and Surgery</MenuItem>
<MenuItem value="BHMS">Bachelor of Homeopathy Medicine and Surgery</MenuItem>
<MenuItem value="BYNS">Bachelor of Yoga and Naturopathy Sciences</MenuItem>
<MenuItem value="B.V.Sc & AH">Bachelor of Veterinary Sciences and Animal Husbandry</MenuItem>
<MenuItem value="BPT">Bachelor of Physiotherapy</MenuItem>
<MenuItem value="B.Sc Microbiology">Bachelor of Science in Microbiology</MenuItem>
<MenuItem value="B.Sc Biotechnology">Bachelor of Science in Biotechnology</MenuItem>
<MenuItem value="GNM">General Nursing and Midwifery</MenuItem>
<MenuItem value="ANM">Auxiliary Nurse-Midwife</MenuItem>
<MenuItem value="OTT">Operation Theater Technician</MenuItem>
<MenuItem value="B.Pharm">Bachelor of Pharmacy</MenuItem>
<MenuItem value="Pharm D">Doctor of Pharmacy</MenuItem>
<MenuItem value="BSc MLT">Bachelor of Science in Medical Laboratory Technology</MenuItem>
<MenuItem value="BSc MRT">Bachelor of Science in Medical Radiologic Technology</MenuItem>
<MenuItem value="BSc AT">Bachelor of Science in Anaesthesia Technology</MenuItem>
<MenuItem value="BSc CPT">Bachelor of Science in Cardiac Perfusion Technology</MenuItem>
<MenuItem value="BSc CVT">Bachelor of Science in Cardiovascular Technology</MenuItem>
<MenuItem value="BSc DIT">Bachelor of Science in Dialysis Therapy</MenuItem>
<MenuItem value="BSc DBS">Bachelor of Science in Diabetes Sciences</MenuItem>
<MenuItem value="BSc ECT">Bachelor of Science in Echocardiography Technology</MenuItem>
<MenuItem value="BSc NEP">Bachelor of Science in Neuro Electro Physiology</MenuItem>
<MenuItem value="BSc PA">Bachelor of Science in Physician Assistant</MenuItem>
<MenuItem value="BSc Respiratory Therapy">Bachelor of Science in Respiratory Therapy</MenuItem>
<MenuItem value="BSc OPT">Bachelor of Science in Optometry</MenuItem>
<MenuItem value="BSc EMT">Bachelor of Science in Emergency Medical Technology</MenuItem>
<MenuItem value="BASLP">Bachelor of Audiology & Speech-Language Pathology</MenuItem>
<MenuItem value="BOT">Bachelor of Occupational Therapy</MenuItem>
<MenuItem value="B.Tech Biomedical Engineering">Bachelor of Technology in Biomedical Engineering</MenuItem>
<MenuItem value="BA Psychology">Bachelor of Arts in Psychology</MenuItem>
<MenuItem value="MMed">Master of Medicine</MenuItem>
<MenuItem value="MSN Practice">Master of Science in Nursing Practice</MenuItem>
<MenuItem value="MS Clinical Research">Master of Science in Clinical Research</MenuItem>
<MenuItem value="MS Anesthesia">Master of Science in Anesthesia</MenuItem>
<MenuItem value="MS Nursing Administration">Master of Science in Nursing Administration</MenuItem>
<MenuItem value="MS Public Health Nursing">Master of Science in Public Health Nursing</MenuItem>
<MenuItem value="MS Speech-Language Pathology">Master of Science in Speech-Language Pathology</MenuItem>
<MenuItem value="MS Health and Rehabilitation Sciences">Master of Science in Health and Rehabilitation Sciences</MenuItem>
<MenuItem value="MS Genetic Counseling">Master of Science in Genetic Counseling</MenuItem>
<MenuItem value="MS Health Systems Management">Master of Science in Health Systems Management</MenuItem>
<MenuItem value="Hospital and Health Administration">Hospital and Health Administration</MenuItem>
<MenuItem value="MHA">Master of Health Administration</MenuItem>
<MenuItem value="MS Healthcare Quality and Safety">Master of Science in Healthcare Quality and Safety</MenuItem>
<MenuItem value="MS Healthcare Management">Master of Science in Healthcare Management</MenuItem>
<MenuItem value="MBA Healthcare">Master of Business Administration (Healthcare)</MenuItem>
<MenuItem value="MS Hospital Administration">Master of Science in Hospital Administration</MenuItem>
<MenuItem value="MHL">Master of Health Leadership</MenuItem>
<MenuItem value="MPA Health Services">Master of Public Administration (Health Services)</MenuItem>
<MenuItem value="MS Medical Informatics">Master of Science in Medical Informatics</MenuItem>
<MenuItem value="MS Disaster Medicine and Management">Master of Science in Disaster Medicine and Management</MenuItem>
<MenuItem value="MS Occupational Health">Master of Science in Occupational Health</MenuItem>
<MenuItem value="MS Biomedical Informatics">Master of Science in Biomedical Informatics</MenuItem>
<MenuItem value="MS Health Economics">Master of Science in Health Economics</MenuItem>
<MenuItem value="MS Nursing Education">Master of Science in Nursing Education</MenuItem>
<MenuItem value="MS Palliative Care">Master of Science in Palliative Care</MenuItem>
<MenuItem value="MA Health Communication">Master of Arts in Health Communication</MenuItem>
<MenuItem value="MA Health Policy">Master of Arts in Health Policy</MenuItem>
<MenuItem value="MS Athletic Training">Master of Science in Athletic Training</MenuItem>
<MenuItem value="MPH">Master of Public Health</MenuItem>
<MenuItem value="MS Nursing">Master of Science in Nursing</MenuItem>
<MenuItem value="MHA">Master of Healthcare Administration</MenuItem>
<MenuItem value="MSW">Master of Social Work</MenuItem>
<MenuItem value="MOT">Master of Occupational Therapy</MenuItem>
<MenuItem value="MSPAS">Master of Science in Physician Assistant Studies</MenuItem>
<MenuItem value="MS Health Informatics">Master of Science in Health Informatics</MenuItem>
<MenuItem value="MS Nutrition">Master of Science in Nutrition</MenuItem>
<MenuItem value="MS Epidemiology">Master of Science in Epidemiology</MenuItem>
<MenuItem value="MScPharm">Master of Science in Pharmacy</MenuItem>
<MenuItem value="MA Counseling">Master of Arts in Counseling</MenuItem>
<MenuItem value="MS Biomedical Science">Master of Science in Biomedical Science</MenuItem>
<MenuItem value="MS Health Promotion">Master of Science in Health Promotion</MenuItem>
<MenuItem value="MS Environmental Health">Master of Science in Environmental Health</MenuItem>
<MenuItem value="MHS">Master of Health Science</MenuItem>
<MenuItem value="Others">Others</MenuItem>

            </Select>
          </FormControl>
          
          {error && (
            <Alert severity="error" className="alert-message2">
              {error}
            </Alert>
          )}

          <div className="group-parent11">
            <div className="rectangle-wrapper3">
              <div className="group-child35" />
            </div>
            <div className="group-child36" />
          </div>
          <Button
            className="content-child20"
            disableElevation={true}
            color="primary"
            variant="contained"
            sx={{ borderRadius: "0px 0px 0px 0px", width: 196, height: 51 }}
            onClick={onFrameButtonClick}
          >
            Continue
          </Button>
          <div className="back10" onClick={onBackTextClick}>
            Back
          </div>
          <div className="education-12">Education 1*</div>
        </div>
      </div>
      <div className="footer16">
        <div className="cevah-parent14">
          <b className="cevah32">CEVAH</b>
          <div className="frame-child33" />
          <div className="frame-child34" />
        </div>
        <div className="lorem-ipsum-dolor20">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `}</div>
        <img className="social-icons48" alt="" src="/vector.svg" />
        <img className="social-icons49" alt="" src="/social-icons.svg" />
        <img className="social-icons50" alt="" src="/social-icons1.svg" />
        <div className="contact-us16">Contact Us</div>
        <div className="support16">Support</div>
        <div className="hellocevahgmailcom-parent14">
          <div className="hellocevahgmailcom16">hellocevah@gmail.com</div>
          <img className="envelope-icon16" alt="" src="/envelope.svg" />
        </div>
        <div className="terms-of-use16">Terms of Use</div>
        <div className="privacy-policy16">Privacy Policy</div>
        <div className="parent18">
          <div className="div23">+919922883377</div>
          <img className="vector-icon25" alt="" src="/vector5.svg" />
        </div>
        <div className="faqs16">FAQs</div>
      </div>
      <div className="header15">
        <div className="content27">
          <div className="logo16" onClick={onLogoContainerClick}>
            <div className="icon18">
              <div className="icon-child30" />
              <div className="icon-child31" />
            </div>
            <b className="cevah33">CEVAH</b>
          </div>
          <div className="navigation16" />
        </div>
        <button className="login7" onClick={onLogout}>
          <div className="sign-in2">Sign Out</div>
        </button>
      </div>
    </div>
  );
};

export default Desktop16;
