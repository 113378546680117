import "./Header.css";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const reportLogo = "/report-logo.png";

const Header = () => {
  const navigate = useNavigate();

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  return (
    <header className="header-container">
      <div className="final-report-intro-section">
        <div className="intro-content-wrapper">
          <div className="branding-container" onClick={onLogoContainerClick}>
          <div className="intro-logo-container">
            <img src={reportLogo} alt="CEVAH Logo" className="intro-logo" />
          </div>
          <div className="intro-description-container">
            <h2>CEVAH</h2>
          </div>
          </div>
          <div className="textbox-contact">
            <button className="signout-header" onClick={onLogout}>
              <div className="signout-button">Sign Out</div>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
