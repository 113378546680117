import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Desktop10.css";

const center = {
  lat: 18.5204,
  lng: 73.8567,
};

const Desktop10 = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleMapClick = (event) => {
    const coordinates = `${event.latLng.lat()},${event.latLng.lng()}`;
    setSelectedLocation(coordinates);
    setShowError(false);
  };

  const parseCoordinates = (coordinates) => {
    const [lat, lng] = coordinates.split(",");
    return { lat: parseFloat(lat), lng: parseFloat(lng) };
  };

  const handleContinue = () => {
    if (!selectedLocation) {
      setShowError(true);
      return;
    }
    localStorage.setItem("selectedLocation", JSON.stringify(selectedLocation));
    navigate("/Speciality");
  };

  const handleBack = () => {
    navigate("/Add-Name");
  };

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  if (loadError) {
    return <div>Error loading map</div>;
  }
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  

  return (
    <div className="address-page">
      <Header />

      <div className="map-container">
        <GoogleMap
          id="map"
          center={center}
          zoom={12}
          onClick={handleMapClick}
        >
          {selectedLocation && <Marker position={parseCoordinates(selectedLocation)} />}
        </GoogleMap>

        <div
          className={`address-error-message ${
            showError ? "address-error-message-visible" : "address-error-message-hidden"
          }`}
        >
          Choose location on map to continue
        </div>

        <div className="address-buttons-container">
          <button className="address-back-button" onClick={handleBack}>
            Back
          </button>
          <button className="address-continue-button" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Desktop10;
