import { useCallback , useState } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  MenuItem, // Import MenuItem
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Desktop7.css";

const Desktop7 = () => {
  const navigate = useNavigate();
  const [degree, setDegree] = useState(""); // State to hold the selected degree
  const [college, setCollege] = useState("")

  const [degree1, setDegree1] = useState(""); // State to hold the selected degree
  const [college1, setCollege1] = useState("")// 
   const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login"); // Ensure correct path for login route
  }, [navigate]);
  const onFrameButtonClick = useCallback(() => {
    localStorage.setItem('college1', college1);
    localStorage.setItem('degree1', degree1);
    navigate("/Education-Details");
  }, [navigate, college1, degree1]);
 
  const onFrameInput1Click = useCallback(() => {
    // Please sync "Desktop - 97" to the project
  }, []);
  const onBackTextClick = useCallback(() => {
    navigate("/Education2");
  }, [navigate]);

  const onSkipTextClick = useCallback(() => {
    navigate("/Education-Details");
  }, [navigate]);

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="desktop-95">
      <div className="hero6">
        <img
          className="ad-placeholder-icon4"
          alt=""
          src="Design4.png"
        />
        <div className="content11">
          <div className="content-child6" />
          <div className="tell-us-about6">Tell us about yourself</div>
          <div className="add-your-educational2">
            Add your educational background. Add upto 3 degrees.
          </div>
          <TextField
            className="content-child7"
            color="primary"
            label="Enter College Name"
            variant="outlined"
            value={college1}
            onChange={(e) => setCollege1(e.target.value)}
            sx={{ "& .MuiInputBase-root": { height: "57px" }, width: "405px" }}
          />
        
          <Select
            className="content-child8"
            value={degree1}
            onChange={(e) => setDegree1(e.target.value)}
            color="primary"
            displayEmpty
            label="Select Degree"
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "57px" }, width: "405px" }}
            onClick={onFrameInput1Click}
          >
            <MenuItem value=""><em>None</em></MenuItem>
<MenuItem value="BDS">Bachelor of Dental Surgery</MenuItem>
<MenuItem value="BAMS">Bachelor of Ayurvedic Medicine and Surgery</MenuItem>
<MenuItem value="BUMS">Bachelor of Unani Medicine and Surgery</MenuItem>
<MenuItem value="BHMS">Bachelor of Homeopathy Medicine and Surgery</MenuItem>
<MenuItem value="BYNS">Bachelor of Yoga and Naturopathy Sciences</MenuItem>
<MenuItem value="B.V.Sc & AH">Bachelor of Veterinary Sciences and Animal Husbandry</MenuItem>
<MenuItem value="BPT">Bachelor of Physiotherapy</MenuItem>
<MenuItem value="B.Sc Microbiology">Bachelor of Science in Microbiology</MenuItem>
<MenuItem value="B.Sc Biotechnology">Bachelor of Science in Biotechnology</MenuItem>
<MenuItem value="GNM">General Nursing and Midwifery</MenuItem>
<MenuItem value="ANM">Auxiliary Nurse-Midwife</MenuItem>
<MenuItem value="OTT">Operation Theater Technician</MenuItem>
<MenuItem value="B.Pharm">Bachelor of Pharmacy</MenuItem>
<MenuItem value="Pharm D">Doctor of Pharmacy</MenuItem>
<MenuItem value="BSc MLT">Bachelor of Science in Medical Laboratory Technology</MenuItem>
<MenuItem value="BSc MRT">Bachelor of Science in Medical Radiologic Technology</MenuItem>
<MenuItem value="BSc AT">Bachelor of Science in Anaesthesia Technology</MenuItem>
<MenuItem value="BSc CPT">Bachelor of Science in Cardiac Perfusion Technology</MenuItem>
<MenuItem value="BSc CVT">Bachelor of Science in Cardiovascular Technology</MenuItem>
<MenuItem value="BSc DIT">Bachelor of Science in Dialysis Therapy</MenuItem>
<MenuItem value="BSc DBS">Bachelor of Science in Diabetes Sciences</MenuItem>
<MenuItem value="BSc ECT">Bachelor of Science in Echocardiography Technology</MenuItem>
<MenuItem value="BSc NEP">Bachelor of Science in Neuro Electro Physiology</MenuItem>
<MenuItem value="BSc PA">Bachelor of Science in Physician Assistant</MenuItem>
<MenuItem value="BSc Respiratory Therapy">Bachelor of Science in Respiratory Therapy</MenuItem>
<MenuItem value="BSc OPT">Bachelor of Science in Optometry</MenuItem>
<MenuItem value="BSc EMT">Bachelor of Science in Emergency Medical Technology</MenuItem>
<MenuItem value="BASLP">Bachelor of Audiology & Speech-Language Pathology</MenuItem>
<MenuItem value="BOT">Bachelor of Occupational Therapy</MenuItem>
<MenuItem value="B.Tech Biomedical Engineering">Bachelor of Technology in Biomedical Engineering</MenuItem>
<MenuItem value="BA Psychology">Bachelor of Arts in Psychology</MenuItem>
<MenuItem value="MMed">Master of Medicine</MenuItem>
<MenuItem value="MSN Practice">Master of Science in Nursing Practice</MenuItem>
<MenuItem value="MS Clinical Research">Master of Science in Clinical Research</MenuItem>
<MenuItem value="MS Anesthesia">Master of Science in Anesthesia</MenuItem>
<MenuItem value="MS Nursing Administration">Master of Science in Nursing Administration</MenuItem>
<MenuItem value="MS Public Health Nursing">Master of Science in Public Health Nursing</MenuItem>
<MenuItem value="MS Speech-Language Pathology">Master of Science in Speech-Language Pathology</MenuItem>
<MenuItem value="MS Health and Rehabilitation Sciences">Master of Science in Health and Rehabilitation Sciences</MenuItem>
<MenuItem value="MS Genetic Counseling">Master of Science in Genetic Counseling</MenuItem>
<MenuItem value="MS Health Systems Management">Master of Science in Health Systems Management</MenuItem>
<MenuItem value="Hospital and Health Administration">Hospital and Health Administration</MenuItem>
<MenuItem value="MHA">Master of Health Administration</MenuItem>
<MenuItem value="MS Healthcare Quality and Safety">Master of Science in Healthcare Quality and Safety</MenuItem>
<MenuItem value="MS Healthcare Management">Master of Science in Healthcare Management</MenuItem>
<MenuItem value="MBA Healthcare">Master of Business Administration (Healthcare)</MenuItem>
<MenuItem value="MS Hospital Administration">Master of Science in Hospital Administration</MenuItem>
<MenuItem value="MHL">Master of Health Leadership</MenuItem>
<MenuItem value="MPA Health Services">Master of Public Administration (Health Services)</MenuItem>
<MenuItem value="MS Medical Informatics">Master of Science in Medical Informatics</MenuItem>
<MenuItem value="MS Disaster Medicine and Management">Master of Science in Disaster Medicine and Management</MenuItem>
<MenuItem value="MS Occupational Health">Master of Science in Occupational Health</MenuItem>
<MenuItem value="MS Biomedical Informatics">Master of Science in Biomedical Informatics</MenuItem>
<MenuItem value="MS Health Economics">Master of Science in Health Economics</MenuItem>
<MenuItem value="MS Nursing Education">Master of Science in Nursing Education</MenuItem>
<MenuItem value="MS Palliative Care">Master of Science in Palliative Care</MenuItem>
<MenuItem value="MA Health Communication">Master of Arts in Health Communication</MenuItem>
<MenuItem value="MA Health Policy">Master of Arts in Health Policy</MenuItem>
<MenuItem value="MS Athletic Training">Master of Science in Athletic Training</MenuItem>
<MenuItem value="MPH">Master of Public Health</MenuItem>
<MenuItem value="MS Nursing">Master of Science in Nursing</MenuItem>
<MenuItem value="MHA">Master of Healthcare Administration</MenuItem>
<MenuItem value="MSW">Master of Social Work</MenuItem>
<MenuItem value="MOT">Master of Occupational Therapy</MenuItem>
<MenuItem value="MSPAS">Master of Science in Physician Assistant Studies</MenuItem>
<MenuItem value="MS Health Informatics">Master of Science in Health Informatics</MenuItem>
<MenuItem value="MS Nutrition">Master of Science in Nutrition</MenuItem>
<MenuItem value="MS Epidemiology">Master of Science in Epidemiology</MenuItem>
<MenuItem value="MScPharm">Master of Science in Pharmacy</MenuItem>
<MenuItem value="MA Counseling">Master of Arts in Counseling</MenuItem>
<MenuItem value="MS Biomedical Science">Master of Science in Biomedical Science</MenuItem>
<MenuItem value="MS Health Promotion">Master of Science in Health Promotion</MenuItem>
<MenuItem value="MS Environmental Health">Master of Science in Environmental Health</MenuItem>
<MenuItem value="Others">Others</MenuItem>
          </Select>
          <div className="group-parent8">
            <div className="rectangle-wrapper1">
              <div className="group-child17" />
            </div>
            <div className="group-child18" />
          </div>
          <Button
            className="content-child9"
            disableElevation={true}
            color="primary"
            variant="contained"
            sx={{ borderRadius: "0px 0px 0px 0px", width: 196, height: 51 }}
            onClick={onFrameButtonClick}
          >
            Continue
          </Button>
          <div className="back3" onClick={onBackTextClick}>
            Back
          </div>
          <div className="education-3-optional">Education 3 (Optional)</div>
          <div className="skip1" onClick={onSkipTextClick}>
            Skip
          </div>
        </div>
      </div>
      <div className="footer7">
        <div className="cevah-parent5">
          <b className="cevah14">CEVAH</b>
          <div className="frame-child14" />
          <div className="frame-child15" />
        </div>
        <div className="lorem-ipsum-dolor9">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `}</div>
        <img className="social-icons21" alt="" src="/vector.svg" />
        <img className="social-icons22" alt="" src="/social-icons.svg" />
        <img className="social-icons23" alt="" src="/social-icons1.svg" />
        <div className="contact-us7">Contact Us</div>
        <div className="support7">Support</div>
        <div className="hellocevahgmailcom-parent5">
          <div className="hellocevahgmailcom7">hellocevah@gmail.com</div>
          <img className="envelope-icon7" alt="" src="/envelope.svg" />
        </div>
        <div className="terms-of-use7">Terms of Use</div>
        <div className="privacy-policy7">Privacy Policy</div>
        <div className="parent9">
          <div className="div13">+919922883377</div>
          <img className="vector-icon16" alt="" src="/vector5.svg" />
        </div>
        <div className="faqs7">FAQs</div>
      </div>
      <div className="header6">
        <div className="content12">
          <div className="logo7" onClick={onLogoContainerClick}>
            <div className="icon7">
              <div className="icon-child12" />
              <div className="icon-child13" />
            </div>
            <b className="cevah15">CEVAH</b>
          </div>
          <div className="navigation7" />
        </div>
        <button className="login7" onClick={onLogout}>
          <div className="sign-in2">Sign Out</div>
        </button> 

      </div>
    </div>
  );
};

export default Desktop7;
