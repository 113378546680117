import React from "react";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";

const Download = () => {
  const navigate = useNavigate();

  const handleDownload = () => {
    navigate("/Download-Report");

    setTimeout(() => {
      const reportElement = document.getElementById("final-report");

      if (reportElement) {

        document.querySelectorAll("section").forEach((section) => {
          section.style.pageBreakInside = "avoid";
          section.style.breakInside = "avoid";
        });

        document.querySelectorAll("table").forEach((table) => {
          table.style.pageBreakInside = "avoid !important";
        });

        document.querySelectorAll("img").forEach((img) => {
          img.style.pageBreakInside = "avoid";
          img.style.breakInside = "avoid";
        });

        const options = {
          margin: 0,
          filename: "CEVAH_Report.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 , useCORS: true},
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        };

        html2pdf()
          .set(options)
          .from(reportElement)
          .save();
      }
    }, 500);
  };

  return (
    <button onClick={handleDownload} className="download-button">
      Download PDF
    </button>
  );
};

export default Download;
