import React from "react";
import "./Testreport.css";
import Download from "../components/Download";

const Testreport = () => {
  return (
    <div className="container">
      <div className="intro">
        <div className="heading">
          <b>Cevah Masterplanning Report</b>
          <div className="download">
            <Download />
          </div>
        </div>
        <p>Welcome to your custom CEVAH Masterplanning Report!</p>
        <p>
          We are delighted to present you with a comprehensive analysis of your
          healthcare facility requirements. This report serves as a roadmap to
          guide you in optimizing the design and layout of your facility,
          ensuring that it meets your unique needs and vision.
        </p>
      </div>
      <div className="report"></div>
      <div className="end"></div>
    </div>
  );
};

export default Testreport;
