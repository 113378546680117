import "./Footer.css";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const reportLogo = "/report-logo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
    <div className="footer-section">
        <div className="footer-logo-container">
        <img src={reportLogo} alt="CEVAH Logo" className="footer-logo" />
        <span className="footer-logo-text">CEVAH</span>
        </div>
        <p className="footer-text">
        At CEVAH, our mission is to empower medical professionals to create exceptional medical facilities that elevate patient care solutions.
        </p>
        {/*<div className="footer-social-icons">
        <a href="https://facebook.com" aria-label="Facebook" className="social-icon">
            <FaFacebook />
        </a>
        <a href="https://instagram.com" aria-label="Instagram" className="social-icon">
            <FaInstagram />
        </a>
        <a href="https://linkedin.com" aria-label="LinkedIn" className="social-icon">
            <FaLinkedin />
        </a>
        </div>*/}
        <p className="copyright-message">&copy; {new Date().getFullYear()} CEVAH.<br /> All rights reserved.</p>
    </div>
    <div className="footer-contact-support">
        <div className="footer-contact">
        <h4>Contact Us</h4>
        <p>+91 9590833718</p>
        <p>cevah@gmail.com</p>
        </div>
        <div className="footer-support">
        {/*<h4>Support</h4>
        <p><a href="/terms">Terms of Use</a></p>
        <p><a href="/privacy">Privacy Policy</a></p>*/}
        </div>
    </div>
    </footer>
  )
}

export default Footer;