import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, FormControl, Checkbox, FormControlLabel } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Desktop15.css";

const Desktop15 = () => {
  const navigate = useNavigate();
  const reportLogo = "/report-logo.png";

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const [nurseRegistration, setNurseRegistration] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    preferred_city: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNurseRegistration((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhoneNumber = (phone) => /^[0-9]{10,15}$/.test(phone);

  const onContinueButtonClick = useCallback(async () => {

    let { first_name, last_name, email, phone_number, preferred_city } = nurseRegistration;

    if (!first_name || !last_name || !email || !phone_number || !preferred_city) {
      alert("All fields are required.");
      return;
    }

    if (!/^[A-Za-z]{2,}$/.test(first_name) || !/^[A-Za-z]{2,}$/.test(last_name)) {
      alert("First and Last Name must contain at least 2 letters and no numbers.");
      return;
    }

    if (!/^[A-Za-z\s]{2,}$/.test(preferred_city)) {
      alert("City name must contain at least 2 letters and no numbers.");
      return;
    }

    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!validatePhoneNumber(phone_number)) {
      alert("Phone number must be between 10 and 15 digits.");
      return;
    }

    if (!agreedToTerms) {
      alert("You must agree to the terms and conditions.");
      return;
    }

    const formattedPayload = {
      first_name,
      last_name,
      email,
      phone_number: phone_number.startsWith("+91") ? phone_number : `+91${phone_number}`,
      preferred_city,
    };

    try {
      const response = await fetch(
        "https://octopus-app-jcpyy.ondigitalocean.app/nurse-applications",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server Response:", errorText);
        throw new Error(`HTTP Error: ${response.status} - ${errorText}`);
      }

      console.log("Success:", await response.json());
      setIsSubmitted(true);
    } catch (error) {
      console.error("Network Error:", error);
    }
  }, [nurseRegistration, agreedToTerms]);

  return (
    <div className="nurseform-page">
      
      <header className="header-container">
        <div className="intro-content-wrapper">
          <div className="branding-container" onClick={onLogoContainerClick}>
            <div className="intro-logo-container">
              <img src={reportLogo} alt="CEVAH Logo" className="intro-logo" />
            </div>
            <div className="intro-description-container">
              <h2>CEVAH</h2>
            </div>
          </div>
        </div>
      </header>

      <div className="nurseform-parent">
        <img className="nurseform-background" src="nurse-reg.jpeg" alt="Background" />

        {!isSubmitted ? (
          <div className="nurseform-container">
            <div className="nurseform-heading">
              <h3>Cevah Nurse Staff Applications {new Date().getFullYear()}</h3>
              <p>CEVAH invites qualified nursing professionals to register for employment opportunities at hospitals across India.</p>
            </div>

            <form className="nurseform-form"
              onSubmit={(e) => {
                e.preventDefault();
                onContinueButtonClick();
              }}
            >
              <div className="nurseform-inputs">
                <FormControl fullWidth className="nurseform-input-wrapper">
                  <TextField
                    label="First Name"
                    name="first_name"
                    value={nurseRegistration.first_name}
                    onChange={handleInputChange}
                    required
                    inputProps={{ pattern: "[A-Za-z]{2,}" }}
                    className="nurseform-input"
                    margin="normal"
                  />
                </FormControl>

                <FormControl fullWidth className="nurseform-input-wrapper">
                  <TextField
                    label="Last Name"
                    name="last_name"
                    value={nurseRegistration.last_name}
                    onChange={handleInputChange}
                    required
                    inputProps={{ pattern: "[A-Za-z]{2,}" }}
                    className="nurseform-input"
                    margin="normal"
                  />
                </FormControl>

                <FormControl fullWidth className="nurseform-input-wrapper">
                  <TextField
                    label="Email Address"
                    type="email"
                    name="email"
                    value={nurseRegistration.email}
                    onChange={handleInputChange}
                    required
                    className="nurseform-input"
                    margin="normal"
                  />
                </FormControl>

                <FormControl fullWidth className="nurseform-input-wrapper">
                  <TextField
                    label="Phone Number"
                    type="tel"
                    name="phone_number"
                    value={nurseRegistration.phone_number}
                    onChange={handleInputChange}
                    required
                    inputProps={{ pattern: "[0-9]{10,15}" }}
                    className="nurseform-input"
                    margin="normal"
                  />
                </FormControl>

                <FormControl fullWidth className="nurseform-input-wrapper">
                  <TextField
                    label="Preferred City to Work"
                    name="preferred_city"
                    value={nurseRegistration.preferred_city}
                    onChange={handleInputChange}
                    required
                    inputProps={{ pattern: "[A-Za-z\\s]{2,}" }}
                    className="nurseform-input"
                    margin="normal"
                  />
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTerms}
                      onChange={(e) => setAgreedToTerms(e.target.checked)}
                      name="agree"
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I agree to the{" "}
                      <a href="" target="_blank" rel="noopener noreferrer">
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>.
                    </span>
                  }
                  className="terms-checkbox"
                />

              </div>

              <div className="nurseform-buttons-container">
                <button type="button" className="nurseform-back-button" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button type="submit" className="nurseform-continue-button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="nurseform-submitted">
            <h3>Your Application has been Submitted</h3>
            <img className="nurseform-tick" alt="form-submitted" src="/frame-36735.svg" />
            <div className="nurseform-return">
              <button className="return-button" onClick={() => navigate("/")}>
                Return to Home
              </button>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Desktop15;
