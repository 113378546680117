import { useNavigate } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import Footer from "../components/Footer";
import "./Desktop.css";

const Desktop = () => {
  
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onRegisterButtonClick = useCallback(() => {
    navigate("/Nurse-Registration");
  }, [navigate]);

  const images = [
    "/testiminols_4.png",
    "/testiminols_3.png",
    "/testiminols_2.png",
    "/testiminols_1.png",
  ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [pauseUntil, setPauseUntil] = useState(0);
    
    useEffect(() => {
      const interval = setInterval(() => {
        if (Date.now() > pauseUntil) {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
      }, 3000);
    
      return () => clearInterval(interval);
    }, [images.length, pauseUntil]);
    
    const nextSlide = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setPauseUntil(Date.now() + 5000);
    };
    
    const prevSlide = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setPauseUntil(Date.now() + 5000);
    };

  return (
    
    <div className="homepage">
    
      <section className="homepage-intro-section">
        <div className="intro-section-container">
          <div className="title-container">
            <h2>CEVAH</h2>
          </div>
          <div className="intro-container">
            <div className="intro-image-container">
              <img src="/docbusiness2-1@2x.png" alt="Intro-image" className="intro-image-element"/>
            </div>
            <div className="intro-text-container">
              <h3>Blueprint to Breakthrough.</h3>
              <p>Turn your healthcare vision into reality with Cevah's masterplanning services<br />— your blueprint for exceptional medical facilities.</p>
            </div>
          </div>
          <div className="getting-started-container">
            <button className="rectangle-container-1" onClick={onGroupButtonClick}>Get Started<span className="button-arrow">↘</span></button>
          </div>
        </div>
      </section>

      <section className="cevah-do-section">
      < div className="cevah-do-heading">
          <h3>What does Cevah do?</h3>
          <p>Medical Masterplanning at your fingertips - Fast, Accessible and Personalized Solutions</p>
        </div>
        <div className="cevah-do-container">
          <div className="cevah-do-box">
            <div className="cevah-do-image">
              <img src="/floorplan3@2x.png" alt="Intro-image" className="cevah-do-image-element" />
            </div>
            <div className="cevah-do-text">
              <p>Empowering medical professionals to realize their healthcare vision by providing support and guidance to navigate the complex process of facility planning.</p>
            </div>
          </div>
          <div className="cevah-do-box">
            <div className="cevah-do-image">
              <img src="/empowering3-1@2x.png" alt="Intro-image" className="cevah-do-image-element" />
            </div>
            <div className="cevah-do-text">
              <p>We offer a comprehensive masterplanning service tailored to optimize the design and layout of medical facilities.</p>
            </div>
          </div>
          <div className="cevah-do-box">
            <div className="cevah-do-image">
              <img src="/lightbulb3-1@2x.png" alt="Intro-image" className="cevah-do-image-element" />
            </div>
            <div className="cevah-do-text">
              <p>Through our innovative online platform, clients can access expert assistance and customized solutions to create exceptional medical facilities.</p>              
            </div>
          </div>
          <div className="cevah-do-box">
            <div className="cevah-do-image">
              <img src="/userfocus.svg" alt="Intro-image" className="cevah-do-image-element" />
            </div>
            <div className="cevah-do-text">
              <p>Our platform provides personalized guidance and tailored recommendations to meet the specific needs of each client.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="cevah-choose-section">
        <div className="cevah-do-heading">
          <h3>Why choose Cevah?</h3>
          <p>Break through barriers and create state of the art medical facilities tailored to your vision</p>
        </div>
        <div className="cevah-choose-container">
          <div className="cevah-choose-content">
            <div className="choose-item">
              <h3>01 <span>Personalized Assistance</span></h3>
              <p>Receive personalized guidance through our intuitive online platform, ensuring your facility exceeds expectations.</p>
            </div>
            <div className="choose-item">
              <h3>02 <span>Tailored Solutions</span></h3>
              <p>Our online tools provide tailored solutions to meet your specific needs and vision, all at your fingertips.</p>
            </div>
            <div className="choose-item">
              <h3>03 <span>Innovative Approach</span></h3>
              <p>With our innovative online masterplanning tools, unlock the full potential of your healthcare practice from anywhere and at anytime.</p>
            </div>
          </div>
          <div className="cevah-choose-image">
            <img src="/docphone@2x.png" alt="Doctor Illustration" className="cevah-choose-image-element" />
          </div>
        </div>
      </section>

      <section className="cevah-testimonial">
        <div className="testimonial-container">
          <div className="testimonial-heading">
            <h3>What do our clients say?</h3>
          </div>
          <div className="testimonial-carousel-container">
            <div className="testimonial-carousel-image-wrapper">
              <img
                src={images[currentImageIndex]}
                alt={`Slide ${currentImageIndex + 1}`}
                className="testimonial-carousel-image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="awaer-ad">
        <div className="awaer-container">
          <div className="awaer-text-container">
            <h3>Discover simple and clean living</h3>
            <p>Affordable air purifier solutions.<br />Learn more at awaer.com</p>
          </div>
          <div className="awaer-image-container">
            <img src="/group.svg" alt="awaer-logo" className="awaer-logo-element"/>
            <img src="/rectangle@2x.png" alt="awaer-image" className="awaer-image-element"/>
          </div>
        </div>
      </section>

      <section className="cevah-nursereg-section">
        <h2>Cevah Nurse Staff Applications {new Date().getFullYear()}</h2>
        <p>Registrations are Open</p>
        <button className="rectangle-container-2" onClick={onRegisterButtonClick}>Register Now</button>
      </section>

      <Footer />

    </div>

  );
};

export default Desktop;