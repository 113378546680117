import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Desktop12.css";

const Desktop12 = () => {
  const navigate = useNavigate();

  const specialties = [
    { name: "Ophthalmology", icon: "/eyeglasses.svg" },
    { name: "Dermatology", icon: "/skin.svg" },
    { name: "Gastroenterology", icon: "/stomach.svg" },
    { name: "Neurology", icon: "/headcircuit.svg" },
    { name: "Cardiology", icon: "/heartbeat.svg" },
    { name: "Oncology", icon: "/cancer-ribbon.svg" },
    { name: "Family Medicine", icon: "/stethoscope.svg" },
    { name: "Anesthesiology", icon: "/syringe.svg" },
    { name: "Immunology", icon: "/icon.svg" },
    { name: "Nephrology", icon: "/kidneys.svg" },
    { name: "Gynecology", icon: "/uterusicon-2.svg" },
    { name: "Pediatrics", icon: "/baby.svg" },
    { name: "Psychiatry", icon: "/brain.svg" },
    { name: "Emergency Medicine", icon: "/ambulance.svg" },
    { name: "Geriatrics", icon: "/old-man.svg" },
    { name: "Radiology", icon: "/layer-1.svg" },
    { name: "Endocrinology", icon: "/heartbeat.svg" },
    { name: "Geneticist", icon: "/testtube.svg" },
    { name: "Hospice & Palliative", icon: "/hospital.svg" },
    { name: "Internal Medicine", icon: "/plant.svg" },
    { name: "Hematology", icon: "/blooddrop.svg" },
    { name: "Infectious Diseases", icon: "/virus.svg" },
    { name: "Osteology", icon: "/bone.svg" },
    { name: "Otolaryngologists", icon: "/frame.svg" },
  ];

  const [showError, setShowError] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties] = useState({});

  const handleSpecialtyClick = (specialty) => {
    setSelectedSpecialties((prevState) => ({
      ...prevState,
      [specialty]: !prevState[specialty],
    }));
  };

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  const onContinueButtonClick = useCallback(async () => {

    const selectedSpecialtiesList = Object.keys(selectedSpecialties)
      .filter((specialty) => selectedSpecialties[specialty])
      .join(", ");

      if (selectedSpecialtiesList.length === 0) {
        setShowError(true);
        return;
      }

    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const phoneNumber = localStorage.getItem("phoneNumber");
    //const college = localStorage.getItem("college");
    //const college1 = localStorage.getItem("college1");
    //const college3 = localStorage.getItem("college3");
    const projectAddress = JSON.parse(localStorage.getItem("selectedLocation"));

    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      //education: [college, college1, college3],
      speciality: selectedSpecialtiesList,
      location: projectAddress,
    };

    try {
      const response = await fetch("https://octopus-app-jcpyy.ondigitalocean.app/details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        navigate("/Calculation-Details");
      } else {
        console.error("API Error:", data.message || "Failed to submit details.");
      }
    } catch (error) {
      console.error("Network Error:", error);
    }
  }, [navigate, selectedSpecialties]);

  const onBackButtonTextClick = useCallback(() => {
    navigate("/Address");
  }, [navigate]);


  {/*}
  const handleDownloadPayload = useCallback(() => {
    const selectedSpecialtiesList = Object.keys(selectedSpecialties)
      .filter((specialty) => selectedSpecialties[specialty])
      .join(", ");

    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const phoneNumber = localStorage.getItem("phoneNumber");
    const projectAddress = JSON.parse(localStorage.getItem("selectedLocation"));

    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      speciality: selectedSpecialtiesList,
      location: projectAddress,
    };

    const blob = new Blob([JSON.stringify(payload, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "payload.txt";
    link.click();
    URL.revokeObjectURL(url);
  }, [selectedSpecialties]);
  */}


  return (
    <div className="specialty-page">

      <Header />
      
      <div className="specialty-parent">
        <img className="specialty-background" src="Desktopmedirailpanels2.png"></img>
        <div className="specialty-container">
          <div className="specialty-heading">
            <h3>Tell us about your Project</h3>
            <p>Select Specialties Offered</p>
          </div>

          <div className="specialty-grid">
            {specialties.map((specialty) => (
              <div key={specialty.name} className={`specialty-item ${selectedSpecialties[specialty.name] ? "selected" : ""}`} onClick={() => handleSpecialtyClick(specialty.name)}>
                <img className="specialty-icon" src={specialty.icon} alt={`${specialty.name} Icon`} />
                <div className="specialty-name">{specialty.name}</div>
              </div>
            ))}
          </div>

          <div className={`specialty-error-message ${showError ? "specialty-error-message-visible" : "specialty-error-message-hidden"}`}>Choose at least one specialty to continue</div>

          <div className="specialty-buttons-container">
            <button className="specialty-back-button" onClick={onBackButtonTextClick}>Back</button>
            {/*<button className="specialty-download-button" onClick={handleDownloadPayload}>Download Payload</button>*/}
            <button className="specialty-continue-button" onClick={onContinueButtonClick}>Continue</button>
          </div>
          
        </div>
      </div>

      <Footer />

    </div>
  );
};

export default Desktop12;
