// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  font-family: Inter;
}

.address-page {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Inter;
}

.map-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 550px;
  padding: 0px 30px;
  padding-bottom: 20px;
}

#map {
  width: 100%;
  height: 100%;
}

.address-buttons-container {
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
}

.address-continue-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 50px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.address-continue-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.address-back-button {
  background-color: transparent;
  border: none;
  font-size: 16x;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.address-error-message {
  display: none;
  margin-top: 20px;
  text-align: center;
  position: inherit;
  color: red;
  font-size: 14px;
}

.address-error-message-visible {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/Desktop10.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;AAC3C;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,cAAc;EACd,eAAe;EACf,0BAA0B;EAC1B,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".body {\n  font-family: Inter;\n}\n\n.address-page {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  font-family: Inter;\n}\n\n.map-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 550px;\n  padding: 0px 30px;\n  padding-bottom: 20px;\n}\n\n#map {\n  width: 100%;\n  height: 100%;\n}\n\n.address-buttons-container {\n  display: flex;\n  padding: 20px 0px;\n  justify-content: space-between;\n}\n\n.address-continue-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 15px 50px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.address-continue-button:hover {\n  background-color: #0056b3;\n  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);\n}\n\n.address-back-button {\n  background-color: transparent;\n  border: none;\n  font-size: 16x;\n  cursor: pointer;\n  text-decoration: underline;\n  transition: color 0.3s ease, border-color 0.3s ease;\n}\n\n.address-error-message {\n  display: none;\n  margin-top: 20px;\n  text-align: center;\n  position: inherit;\n  color: red;\n  font-size: 14px;\n}\n\n.address-error-message-visible {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
