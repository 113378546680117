import "./report.css";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Download from "../components/Download";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const reportLogo = "/report-logo.png";

const Report = () => {
  const navigate = useNavigate();

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  const images = [
    "/carousel-image1.jpg",
    "/carousel-image2.jpg",
    "/carousel-image3.jpg",
  ];

  const [numberOfFloors, setNumberOfFloors] = useState("");
  const [floorDetails, setFloorDetails] = useState([]);
  const [siteInfo, setSiteInfo] = useState({
    sqFt: "",
    carpetArea: "",
    heightRestriction: "",
    totalBuiltUpArea: "",
    fsi: "",
    fsiHeight: "",
  });

  const [equipmentDetails, setEquipmentDetails] = useState({
    OT: ["", "", ""],
    ICU: ["", "", ""],
    PRIVATE_ROOM: ["", "", ""],
  });

  const [gFloorInfo, setGFloorInfo] = useState({
    opdArea: "",
    opd: "",
    diagnosticArea: "",
    circulationArea: "",
    pharmacyArea: "",
    receptionArea: "",
    totalGroundFloorArea: "",
    casualtyArea: "",
    casualtyBed: "",
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    icu: { totalArea: "", totalBeds: "" },
    isolation: { totalArea: "", totalBeds: "" },
    nursingStation: "",
    circulation: "",
    miscellaneous: "",
    semiIcu: { totalArea: "", totalBeds: "" },
    lastFloor: { OT: "", numOT: "", circulation: "", miscelleneous: "" },
  });

  useEffect(() => {
    const storedResponse = localStorage.getItem("apiResponse");
    if (storedResponse) {
      const data = JSON.parse(storedResponse);

      setSiteInfo({
        sqFt: data.total_built_up_area,
        carpetArea: data.carpet_area,
        heightRestriction: "",
        totalBuiltUpArea: data.total_built_up_area,
        fsi: data.FSI,
        fsiHeight: "",
      });

      setGFloorInfo({
        opdArea: data.ground_floor_area[0].opd[0],
        opd: data.ground_floor_area[0].opd[1],
        diagnosticArea: data.ground_floor_area[0].diagnostic,
        circulationArea: data.ground_floor_area[0].circulation,
        pharmacyArea: data.ground_floor_area[0].pharmacy,
        receptionArea: data.ground_floor_area[0].reception,
        totalGroundFloorArea: data.ground_floor_area[0].total_area,
        casualtyArea: data.ground_floor_area[0].casualty[0],
        casualtyBed: data.ground_floor_area[0].casualty[1]
      });

      const numFloors = data.no_of_floors;
      setNumberOfFloors(numFloors);

      const updatedDetails = data.floors_area.map((floor, index) => ({
        floorNumber: index + 1,
        floorArea: floor.total_area,
        floorRestriction: "",
        totalFloorArea: floor.total_area,
        totalAreaPrivateRooms: floor.private[0],
        numBedsPrivate: floor.private[1],
        totalAreaSemiPrivateRooms: floor.semi_private[0],
        numBedsSemiPrivate: floor.semi_private[1],
        circulationArea: floor.circulation,
      }));
      setFloorDetails(updatedDetails);

      setAdditionalInfo({
        icu: {
          totalArea: data.ICU.total_area,
          totalBeds: data.ICU.total_beds,
        },
        isolation: {
          totalArea: data.isolation.total_area,
          totalBeds: data.isolation.total_beds,
        },
        nursingStation: data.nursing_station,
        circulation: data.circulation,
        miscellaneous: data.miscelleneous,
        semiIcu: {
          totalArea: data.semi_icu.total_area,
          totalBeds: data.semi_icu.total_beds,
        },
        lastFloor: {
          OT: data.last_floor.OT,
          numOT: data.last_floor.no_of_ot,
          circulation: data.last_floor.circulation,
          miscelleneous: data.last_floor.miscelleneous,
        },
      });
    
      setEquipmentDetails({
        OT: data.list_of_equipments.OT,
        ICU: data.list_of_equipments.ICU,
        PRIVATE_ROOM: data.list_of_equipments.PRIVATE_ROOM
      });  

    }  
  }, []);

  const handleNumberOfFloorsChange = (e) => {
    const numFloors = parseInt(e.target.value) || "";
    setNumberOfFloors(numFloors);

    const updatedDetails = Array.from({ length: numFloors }, (_, index) => ({
      floorNumber: index + 1,
      floorArea: "",
      floorRestriction: "",
      totalFloorArea: "",
      totalAreaPrivateRooms: "",
      numBedsPrivate: "",
      totalAreaSemiPrivateRooms: "",
      numBedsSemiPrivate: "",
      circulationArea: "",
    }));
    setFloorDetails(updatedDetails);
  };

  return (
    <div className="final-report" id="final-report">
      
      <section className="header-section">
        <div className="final-report-intro-section">
          <div className="intro-content-wrapper">
            <div className="branding-container" onClick={onLogoContainerClick}>
            <div className="intro-logo-container">
              <img src={reportLogo} alt="CEVAH Logo" className="intro-logo" />
            </div>
            <div className="intro-description-container">
              <h2>CEVAH</h2>
            </div>
            </div>
            <div className="textbox-contact">
              <p className="textbox-contact-detail">
                <FaPhoneAlt alt="Phone" className="icon" /> +91 9595833718
                <br />
                <FaEnvelope alt="Email" className="icon" /> cevah9@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div className="text-image-section">
          <div className="text-container">
            <div className="text-content">
              <h3>Custom Masterplanning Report</h3>
              <p>
                Welcome to your custom CEVAH Masterplanning Report!<br />
                <span className="paragraph-space">
                  We are delighted to present you with a comprehensive analysis of your healthcare facility requirements.<br /> This report serves as a roadmap to guide you in optimizing the design and layout of your facility, ensuring that it meets your unique needs and vision.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="text-image-container">
        <div className="image-content">
          <img src="/hospital-buildingamico-2-1@2x.png" alt="Our Mission" className="image-element" />
          </div>
        </div>
      </section>

      <div class="page-break"></div>

      <section className="table-section">
        <div className="table-container">
            <h3 className="table-title">Site Information</h3>
            <table className="info-table">
              <tbody>
                <tr>
                  <td>Total carpet area (sq ft):</td>
                  <td>{siteInfo.carpetArea}</td>
                </tr>
                <tr>
                  <td>Total built up area (sq ft):</td>
                  <td>{siteInfo.totalBuiltUpArea}</td>
                </tr>
                <tr>
                  <td>Number of floors :</td>
                  <td>
                    <label className="new-value" onChange={handleNumberOfFloorsChange}>{numberOfFloors}</label>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </section>

      <section className="text-image-section">
        <div className="text-image-container">
        <div className="image-content">
            <img src="/researchingamico-4@2x.png" alt="Our Mission" className="image-element" />
          </div>
        </div>
      </section>

      <section className="table2-section">
        <div className="table2-container">
          <div className="table2-title">
            <span className="title-main">G</span>
            <span className="title-sub">Floor</span>
          </div>
          <table className="info-table2">
            <tbody>
              <tr>
                <td>OPD area (sq ft):</td>
                <td>{gFloorInfo.opdArea}</td>
              </tr>
              <tr>
                <td>No. of OPD's:</td>
                <td>{gFloorInfo.opd}</td>
              </tr>
              <tr>
                <td>Diagnostic area (sq ft):</td>
                <td>{gFloorInfo.diagnosticArea}</td>
              </tr>
              <tr>
                <td>Circulation area (sq ft):</td>
                <td>{gFloorInfo.circulationArea}</td>
              </tr>
              <tr>
                <td>Pharmacy area (sq ft):</td>
                <td>{gFloorInfo.pharmacyArea}</td>
              </tr>
              <tr>
                <td>Reception area (sq ft):</td>
                <td>{gFloorInfo.receptionArea}</td>
              </tr>
              <tr>
                <td>Total ground floor area (sq ft):</td>
                <td>{gFloorInfo.totalGroundFloorArea}</td>
              </tr>
              <tr>
                <td>Casualty area (sq ft):</td>
                <td>{gFloorInfo.casualtyArea}</td>
              </tr>
              <tr>
                <td>No. of Casualty Beds:</td>
                <td>{gFloorInfo.casualtyBed}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="text-image-section">
        <div className="text-image-container">
        <div className="image-content">
            <img src="/hospital-roomamico@2x.png" alt="Our Mission" className="image-element" />
          </div>
        </div>
      </section>

      <div class="page-break"></div>

      <section className="table2-section">
        {floorDetails.map((floor, index) => (
        <div className="table2-container">
          <div className="table2-title">
            <span className="title-main">{floor.floorNumber}</span>
            <span className="title-sub">Floor</span>
          </div>
          <table className="info-table2">
            <tbody>
              <tr>
                <td>Total floor area (sq ft):</td>
                <td>{floor.floorArea}</td>
              </tr>
              <tr>
                <td>Total private rooms area (sq ft):</td>
                <td>{floor.totalAreaPrivateRooms}</td>
              </tr>
              <tr>
                <td>Number of beds in private rooms:</td>
                <td>{floor.numBedsPrivate}</td>
              </tr>
              <tr>
                <td>Total area of semi-private rooms (sq ft):</td>
                <td>{floor.totalAreaSemiPrivateRooms}</td>
              </tr>
              <tr>
                <td>Number of beds in semi-private rooms:</td>
                <td>{floor.numBedsSemiPrivate}</td>
              </tr>
              <tr>
                <td>Circulation area (sq ft):</td>
                <td>{floor.circulationArea}</td>
              </tr>
            </tbody>
          </table>
        </div>
        ))}
      </section>

      <section className="table2-section">
        <div className="table2-container">
          <div className="table2-title">
            <span className="title-main">{floorDetails.length + 1}</span>
            <span className="title-sub">Floor</span>
          </div>
          <table className="info-table2">
            <tbody>
              <tr>
                <td>ICU total area (sq ft):</td>
                <td>{additionalInfo.icu.totalArea}</td>
              </tr>
              <tr>
                <td>ICU total beds:</td>
                <td>{additionalInfo.icu.totalBeds}</td>
              </tr>
              <tr>
                <td>Isolation total area (sq ft):</td>
                <td>{additionalInfo.isolation.totalArea}</td>
              </tr>
              <tr>
                <td>Isolation total beds: </td>
                <td>{additionalInfo.isolation.totalBeds}</td>
              </tr>
              <tr>
                <td>Nursing station area (sq ft):</td>
                <td>{additionalInfo.nursingStation}</td>
              </tr>
              <tr>
                <td>Circulation area (sq ft):</td>
                <td>{additionalInfo.circulation}</td>
              </tr>
              <tr>
                <td>Miscellaneous area (sq ft):</td>
                <td>{additionalInfo.miscellaneous}</td>
              </tr>
              <tr>
                <td>Semi-ICU total area (sq ft):</td>
                <td>{additionalInfo.semiIcu.totalArea}</td>
              </tr>
              <tr>
                <td>Semi-ICU total beds:</td>
                <td>{additionalInfo.semiIcu.totalBeds}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="table-section">
        <div className="table-container">
            <h3 className="table-title">Last Floor</h3>
            <table className="info-table">
              <tbody>
                <tr>
                  <td>Operating Theatre (OT) area (sq ft):</td>
                  <td>{additionalInfo.lastFloor.OT}</td>
                </tr>
                <tr>
                  <td>Number of Operating Theatre (OT):</td>
                  <td>{additionalInfo.lastFloor.numOT}</td>
                </tr>
                <tr>
                  <td>Circulation area (sq ft):</td>
                  <td>{additionalInfo.lastFloor.circulation}</td>
                </tr>
                <tr>
                  <td>Miscellaneous area (sq ft):</td>
                  <td>{additionalInfo.lastFloor.miscelleneous}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </section>

      <section className="multi-table-container">
        <div className="multi-table-container"> 
        <h3 className="multi-table-title">List of Required Equipments</h3>
          
          <section className="multi-table-section">
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>OT</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.PRIVATE_ROOM.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>OT pendant system</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Air Steriliser</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Anesthesia Machine</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>OT Table</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Anesthesia Cart</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Anesthesia Circuits</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Anesthesia Monitor - CO2 and Agents</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Laryngeal Mask Airways</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Surgical Table</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Patient Stretcher</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Patient Stirrups</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Positioning Pillows</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Restraint Straps</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Patient Positioners</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Surgical Headlight</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Surgical Lights - Ceiling Mounted</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Surgical Microscope</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>AED / Defibrillator</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Vital Signs Monitor</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>EKG Machine</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Video Monitors and Cameras</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>C-Arms</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Ultrasound Machines</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Endoscopy Tower</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Laryngoscopes</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Electrosurgical Unit</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Smoke Evacuator</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Sequential Compression Device</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Surgical Tourniquet</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Nerve Stimulator</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Blood Warmer</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
                <tr><td>Warming Air Units</td><td>{additionalInfo.lastFloor.numOT}</td></tr>
              </tbody>
            </table>
          </section>
          
          <section className="multi-table-section">
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>ICU</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.OT.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>ICU Pendant/Bed Head Panel</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Anesthesia Machine</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Respiratory Ventilator</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>ICU Bed</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Medical Stretcher</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Patient Monitor</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Syringe Pump</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Infusion Pump</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Ultrasound Machine</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>EKG Machine</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Vital Signs Monitor</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Sequential Compression Device</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Enteral Feeding Pump</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Curtains</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Video Laryngoscopes</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Laryngoscope Blades</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Laryngoscope Handles</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Endotracheal Tubes</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Intubating Stylets</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Laryngeal Mask Airways</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Nasopharyngeal / Oropharyngeal / Guedel Airways</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Anesthesia Masks</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Cannulas</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Breathing Circuits</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Patient Cables</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Modules</td><td>{additionalInfo.icu.totalBeds}</td></tr>
                <tr><td>Batteries</td><td>{additionalInfo.icu.totalBeds}</td></tr>
              </tbody>
            </table>
          </section>
          
          <section className="multi-table-section">
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>OPD</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.OT.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>Curtain</td><td>{gFloorInfo.opd}</td></tr>
                <tr><td>Table</td><td>{gFloorInfo.opd}</td></tr>
                <tr><td>Examination Table</td><td>{gFloorInfo.opd}</td></tr>
                <tr><td>Illuminator</td><td>{gFloorInfo.opd}</td></tr>
                <tr><td>Storage</td><td>{gFloorInfo.opd}</td></tr>
              </tbody>
            </table>
          </section>

          <section className="multi-table-section">
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>Casualty</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.OT.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>ICU Pendant/Bed Head Panel</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Anesthesia Machine</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Respiratory Ventilator</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>ICU Bed</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Medical Stretcher</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Patient Monitor</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Syringe Pump</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Infusion Pump</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Ultrasound Machine</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>EKG Machine</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Vital Signs Monitor</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Sequential Compression Device</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Enteral Feeding Pump</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Curtains</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Video Laryngoscopes</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Laryngoscope Blades</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Laryngoscope Handles</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Endotracheal Tubes</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Intubating Stylets</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Laryngeal Mask Airways</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Nasopharyngeal / Oropharyngeal / Guedel Airways</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Anesthesia Masks</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Cannulas</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Breathing Circuits</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Patient Cables</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Modules</td><td>{gFloorInfo.casualtyBed}</td></tr>
                <tr><td>Batteries</td><td>{gFloorInfo.casualtyBed}</td></tr>
              </tbody>
            </table>
          </section>

          <section className="multi-table-section">
          <p>This is break text</p>
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>Private Rooms</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.ICU.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>Bed</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Bed Head Panel</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Gas Outlets</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Electric Switches</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Curtain</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Monitor Stand</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Basket</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Relative Bed</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>TV</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Cupboard</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
                <tr><td>Bedside Trolley</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0)}</td></tr>
              </tbody>
            </table>
          </section>

          <section className="multi-table-section">
            <p>This is break text</p>
            <table className="multi-info-table">
              <thead>
                <tr>
                  <th>Semi Private Rooms</th>
                </tr>
                <tr>
                  <th>Item Description</th>
                  <th>Base Quantity</th>
                </tr>
              </thead>
              <tbody>
                {/*{equipmentDetails.OT.map((item, index) => (
                  <tr key={index}>
                  ))}
                */}
                <tr><td>Bed</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Bed Head Panel</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Gas Outlets</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Electric Switches</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Curtain</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Monitor Stand</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Basket</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Relative Bed</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>TV</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Cupboard</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
                <tr><td>Bedside Trolley</td><td>{floorDetails.reduce((total, floor) => total + parseInt(floor.numBedsPrivate), 0) * 2}</td></tr>
              </tbody>
            </table>
          </section>

        </div>
      </section>

      <div className="ending-message">
        <p>This is break text<br /></p>
        <span>
        Thank you for entrusting CEVAH with your facility planning needs. Together, we'll shape a future where patient care is elevated, compassion is paramount, and medical facilities are designed to inspire healing and innovation.
        </span>
        <span>
        We are committed to supporting you every step of the way, from concept to completion, to ensure that your facility exceeds expectations and delivers exceptional patient care.
        </span>
        <span>CEVAH Masterplanning Team</span>
      </div>

      <div class="page-break"></div>

      <section className="carousel-showcase">
        {images.map((src, index) => (
        <div>
          <p>This is break text</p>
          <img key={index} src={src} alt={`Carousel ${index + 1}`}/>
        </div>
        ))}
      </section>
      
      <section className="section-footer">
        <Footer />
      </section>

    </div>
  );
};

export default Report;