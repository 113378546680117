import { useCallback, useState } from "react";
import {
  TextField,

  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Desktop6.css";

const Desktop6 = () => {
  const navigate = useNavigate();
  const [college, setCollege] = useState('');
  const [degree, setDegree] = useState('');
   const onLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login"); // Ensure correct path for login route
  }, [navigate]);
  const handleChangeCollege = (event) => {
    setCollege(event.target.value);
  };

  const handleChangeDegree = (event) => {
    setDegree(event.target.value);
  };

  const onFrameButtonClick = useCallback(() => {
    localStorage.setItem('college', college);
    navigate("/Education3");
  }, [navigate, college, degree]);


  const onBackTextClick = useCallback(() => {
    navigate("/Education1");
  }, [navigate]);

  const onSkipTextClick = useCallback(() => {
    navigate("/Education-Details");
  }, [navigate]);

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="desktop-185">
      <div className="hero5">
        <img
          className="ad-placeholder-icon3"
          alt=""
          src="Design2.png"
        />
        <div className="content9">
          <div className="content-child2" />
          <div className="tell-us-about5">Tell us about yourself</div>
          <div className="add-your-educational1">
            Add your educational background. Add upto 3 degrees.
          </div>
          <TextField
            className="content-child3"
            color="primary"
            label="Enter College Name"
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "57px" }, width: "405px" }}
            onChange={handleChangeCollege}
            value={college}
          />
         
          <FormControl
            variant="outlined"
            sx={{ m: 1, width: 405 }}
            className="content-child4"
          >
            <InputLabel id="select-degree-label">Enter Degree Name</InputLabel>
            <Select
              labelId="select-degree-label"
              id="select-degree"
              value={degree}
              onChange={handleChangeDegree}
              label="Enter Degree Name"
              sx={{ height: 57 }}
            >
             <MenuItem value=""><em>None</em></MenuItem>
<MenuItem value="BDS">Bachelor of Dental Surgery</MenuItem>
<MenuItem value="BAMS">Bachelor of Ayurvedic Medicine and Surgery</MenuItem>
<MenuItem value="BUMS">Bachelor of Unani Medicine and Surgery</MenuItem>
<MenuItem value="BHMS">Bachelor of Homeopathy Medicine and Surgery</MenuItem>
<MenuItem value="BYNS">Bachelor of Yoga and Naturopathy Sciences</MenuItem>
<MenuItem value="B.V.Sc & AH">Bachelor of Veterinary Sciences and Animal Husbandry</MenuItem>
<MenuItem value="BPT">Bachelor of Physiotherapy</MenuItem>
<MenuItem value="B.Sc Microbiology">Bachelor of Science in Microbiology</MenuItem>
<MenuItem value="B.Sc Biotechnology">Bachelor of Science in Biotechnology</MenuItem>
<MenuItem value="GNM">General Nursing and Midwifery</MenuItem>
<MenuItem value="ANM">Auxiliary Nurse-Midwife</MenuItem>
<MenuItem value="OTT">Operation Theater Technician</MenuItem>
<MenuItem value="B.Pharm">Bachelor of Pharmacy</MenuItem>
<MenuItem value="Pharm D">Doctor of Pharmacy</MenuItem>
<MenuItem value="BSc MLT">Bachelor of Science in Medical Laboratory Technology</MenuItem>
<MenuItem value="BSc MRT">Bachelor of Science in Medical Radiologic Technology</MenuItem>
<MenuItem value="BSc AT">Bachelor of Science in Anaesthesia Technology</MenuItem>
<MenuItem value="BSc CPT">Bachelor of Science in Cardiac Perfusion Technology</MenuItem>
<MenuItem value="BSc CVT">Bachelor of Science in Cardiovascular Technology</MenuItem>
<MenuItem value="BSc DIT">Bachelor of Science in Dialysis Therapy</MenuItem>
<MenuItem value="BSc DBS">Bachelor of Science in Diabetes Sciences</MenuItem>
<MenuItem value="BSc ECT">Bachelor of Science in Echocardiography Technology</MenuItem>
<MenuItem value="BSc NEP">Bachelor of Science in Neuro Electro Physiology</MenuItem>
<MenuItem value="BSc PA">Bachelor of Science in Physician Assistant</MenuItem>
<MenuItem value="BSc Respiratory Therapy">Bachelor of Science in Respiratory Therapy</MenuItem>
<MenuItem value="BSc OPT">Bachelor of Science in Optometry</MenuItem>
<MenuItem value="BSc EMT">Bachelor of Science in Emergency Medical Technology</MenuItem>
<MenuItem value="BASLP">Bachelor of Audiology & Speech-Language Pathology</MenuItem>
<MenuItem value="BOT">Bachelor of Occupational Therapy</MenuItem>
<MenuItem value="B.Tech Biomedical Engineering">Bachelor of Technology in Biomedical Engineering</MenuItem>
<MenuItem value="BA Psychology">Bachelor of Arts in Psychology</MenuItem>
<MenuItem value="MMed">Master of Medicine</MenuItem>
<MenuItem value="MSN Practice">Master of Science in Nursing Practice</MenuItem>
<MenuItem value="MS Clinical Research">Master of Science in Clinical Research</MenuItem>
<MenuItem value="MS Anesthesia">Master of Science in Anesthesia</MenuItem>
<MenuItem value="MS Nursing Administration">Master of Science in Nursing Administration</MenuItem>
<MenuItem value="MS Public Health Nursing">Master of Science in Public Health Nursing</MenuItem>
<MenuItem value="MS Speech-Language Pathology">Master of Science in Speech-Language Pathology</MenuItem>
<MenuItem value="MS Health and Rehabilitation Sciences">Master of Science in Health and Rehabilitation Sciences</MenuItem>
<MenuItem value="MS Genetic Counseling">Master of Science in Genetic Counseling</MenuItem>
<MenuItem value="MS Health Systems Management">Master of Science in Health Systems Management</MenuItem>
<MenuItem value="Hospital and Health Administration">Hospital and Health Administration</MenuItem>
<MenuItem value="MHA">Master of Health Administration</MenuItem>
<MenuItem value="MS Healthcare Quality and Safety">Master of Science in Healthcare Quality and Safety</MenuItem>
<MenuItem value="MS Healthcare Management">Master of Science in Healthcare Management</MenuItem>
<MenuItem value="MBA Healthcare">Master of Business Administration (Healthcare)</MenuItem>
<MenuItem value="MS Hospital Administration">Master of Science in Hospital Administration</MenuItem>
<MenuItem value="MHL">Master of Health Leadership</MenuItem>
<MenuItem value="MPA Health Services">Master of Public Administration (Health Services)</MenuItem>
<MenuItem value="MS Medical Informatics">Master of Science in Medical Informatics</MenuItem>
<MenuItem value="MS Disaster Medicine and Management">Master of Science in Disaster Medicine and Management</MenuItem>
<MenuItem value="MS Occupational Health">Master of Science in Occupational Health</MenuItem>
<MenuItem value="MS Biomedical Informatics">Master of Science in Biomedical Informatics</MenuItem>
<MenuItem value="MS Health Economics">Master of Science in Health Economics</MenuItem>
<MenuItem value="MS Nursing Education">Master of Science in Nursing Education</MenuItem>
<MenuItem value="MS Palliative Care">Master of Science in Palliative Care</MenuItem>
<MenuItem value="MA Health Communication">Master of Arts in Health Communication</MenuItem>
<MenuItem value="MA Health Policy">Master of Arts in Health Policy</MenuItem>
<MenuItem value="MS Athletic Training">Master of Science in Athletic Training</MenuItem>
<MenuItem value="MPH">Master of Public Health</MenuItem>
<MenuItem value="MS Nursing">Master of Science in Nursing</MenuItem>
<MenuItem value="MHA">Master of Healthcare Administration</MenuItem>
<MenuItem value="MSW">Master of Social Work</MenuItem>
<MenuItem value="MOT">Master of Occupational Therapy</MenuItem>
<MenuItem value="MSPAS">Master of Science in Physician Assistant Studies</MenuItem>
<MenuItem value="MS Health Informatics">Master of Science in Health Informatics</MenuItem>
<MenuItem value="MS Nutrition">Master of Science in Nutrition</MenuItem>
<MenuItem value="MS Epidemiology">Master of Science in Epidemiology</MenuItem>
<MenuItem value="MScPharm">Master of Science in Pharmacy</MenuItem>
<MenuItem value="MA Counseling">Master of Arts in Counseling</MenuItem>
<MenuItem value="MS Biomedical Science">Master of Science in Biomedical Science</MenuItem>
<MenuItem value="MS Health Promotion">Master of Science in Health Promotion</MenuItem>
<MenuItem value="MS Environmental Health">Master of Science in Environmental Health</MenuItem>
<MenuItem value="MHS">Master of Health Science</MenuItem>
<MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          <div className="group-parent7">
            <div className="rectangle-frame">
              <div className="group-child15" />
            </div>
            <div className="group-child16" />
          </div>
          <Button
            className="continue2"
            disableElevation={true}
            color="primary"
            variant="contained"
            sx={{ borderRadius: "0px 0px 0px 0px", width: 196, height: 51 }}
            onClick={onFrameButtonClick}
          >
            Continue
          </Button>
          <div className="back2" onClick={onBackTextClick}>
            Back
          </div>
          <div className="education-2-optional">Education 2 (Optional)</div>
          <div className="skip" onClick={onSkipTextClick}>
            Skip
          </div>
        </div>
      </div>
      <div className="footer6">
        <div className="cevah-parent4">
          <b className="cevah12">CEVAH</b>
          <div className="frame-child12" />
          <div className="frame-child13" />
        </div>
        <div className="lorem-ipsum-dolor8">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. `}</div>
        <img className="social-icons18" alt="" src="/vector.svg" />
        <img className="social-icons19" alt="" src="/social-icons.svg" />
        <img className="social-icons20" alt="" src="/social-icons1.svg" />
        <div className="contact-us6">Contact Us</div>
        <div className="support6">Support</div>
        <div className="hellocevahgmailcom-parent4">
          <div className="hellocevahgmailcom6">hellocevah@gmail.com</div>
          <img className="envelope-icon6" alt="" src="/envelope.svg" />
        </div>
        <div className="terms-of-use6">Terms of Use</div>
        <div className="privacy-policy6">Privacy Policy</div>
        <div className="parent8">
          <div className="div12">+919922883377</div>
          <img className="vector-icon15" alt="" src="/vector5.svg" />
        </div>
        <div className="faqs6">FAQs</div>
      </div>
      <div className="header5">
        <div className="content10">
          <div className="logo6" onClick={onLogoContainerClick}>
            <div className="icon6">
              <div className="icon-child10" />
              <div className="icon-child11" />
            </div>
            <b className="cevah13">CEVAH</b>
          </div>
          <div className="navigation6" />
        </div>
        <button className="login7" onClick={onLogout}>
          <div className="sign-in2">Sign Out</div>
        </button> 

      </div>
    </div>
  );
};

export default Desktop6;
